import './style.css'
import YouTube from 'react-youtube'
import H from '../../../atoms/H/H'
import Divider_ from '../../../atoms/Divider/Divider'
import Text_ from "../../../atoms/Text/Text"

const YOUTUBE_CONFIG = { height: '390', width: '100%', playerVars: { autoplay: 0 } }

export default function AboutUsPage() {
  const youtubeId = process.env.REACT_APP_ABOUT_US_YOUTUBE_ID

  function numberIcon(number: number) {
    return <div className={`about_us__how_it_works__number_${number}`}>
      {number}
    </div>
  }

  return <div className='about_us'>
    <H text='How iUjex works' variant='5' className='about_us__how_it_works__title'/>
    {numberIcon(1)}
    <H text='Create Your Order' variant='6' className='about_us__how_it_works__text_1'/>
    {numberIcon(2)}
    <H text='Pay online' variant='6' className='about_us__how_it_works__text_2'/>
    {numberIcon(3)}
    <H text='Place Your Order ID on Your shipment' variant='6' className='about_us__how_it_works__text_3'/>
    <H text='We will take care of the rest!' variant='6' className='about_us__how_it_works__text_4'/>
    {/*https://developers.google.com/youtube/player_parameters*/}
    {youtubeId && <YouTube videoId={youtubeId} opts={YOUTUBE_CONFIG} onError={(e: any) => console.log(`AboutUsPage: Error: ${e}`)} className='about_us__how_it_works__video'/>}
    <Divider_ text='About Us' className='about_us__divider__description'/>
    <Text_ className='about_us__description'>
      iUjex, a California-based delivery company founded in 2020, offers eco-friendly shipping solutions.
      Our peer-to-peer network connects drivers with individuals needing parcel deliveries along existing routes between the Bay Area and Los Angeles, eliminating warehouses and large fleets for faster more affordable service.<br/>
      We also operate our own fleet of cargo vans throughout California and within a 1000-mile radius for commercial and personal shipments. We plan to expand soon.
    </Text_>
  </div>
}
